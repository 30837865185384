import { FC, ChangeEvent, useState, useRef } from 'react'
import DropZoneProps from './DropZone.interface'
import styles from './DropZone.module.scss'

const DropZone: FC<DropZoneProps> = ({
  label,
  onFileChange,
  register,
  validation,
  name
}) => {
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    processFiles(files);
  };

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    processFiles(files);
    setIsDragOver(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const processFiles = (files: FileList | null) => {
    if (files && files[0]) {
      setUploadedFileName(files[0].name);
      onFileChange(files[0]);
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLLabelElement>) => {
    // Check if the new target is outside the drop zone
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setIsDragOver(false);
    }
  };

  const inputReference = useRef<HTMLInputElement | null>(null)
  const {ref, ...rest} =  (register && validation) ? register(name, {...validation}) : {ref: null}

  return (
    <div className={styles.root}>
    <div className='w-full'>
      <p className='block mb-2 typo-p-small font-medium text-gray-900 dark:text-white'>{label}</p>
    <label 
      className={`${styles.dropZone} ${isDragOver ? styles.dragOver: ""}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={() => setIsDragOver(true)}
      onDragLeave={handleDragLeave}
    >
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
            {!uploadedFileName && 
            <>
            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p className="mb-2 typo-p-medium text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="typo-p-small text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF</p>
            </>}
        </div>
        <input
          {...rest}
          ref={(e) => {
            ref && ref(e)
            inputReference.current = e
          }}
          type="file"
          className="hidden"
          onChange={handleFileChange}
        />
        {uploadedFileName && <p className="mb-2 typo-p-small text-gray-500 dark:text-gray-400">Uploaded: {uploadedFileName}</p>}
    </label>
    </div>
</div> 
  )
}

export default DropZone
