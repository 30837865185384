import { 
  funnelDataQuery,
  primaryAccountQuery,
  listCardProgramsQuery,
  listTransactionsQuery,
  listUsersQuery,
  getPublishableKeyQuery,
  getTransactionDataQuery,
  getOffersQuery
} from "../../graphql/queries"
import styles from "./dashboard.module.scss"
import { useQuery, useMutation } from 'urql';
import { toUsd, handleFilter  } from "../../utils/data"
import Modal from "../../components/Modal";
import { useMemo, useState,useEffect, useRef } from "react";
import CurrencyInput from 'react-currency-input-field';
import { toast } from "react-toastify";
import { sendFundsMutation, createPaymentIntentMutation, sendOfferMutation } from "../../graphql/mutations"
import Spinner from '../../components/Spinner';
import Card from "../../components/card";
import Table from '../../components/Table'
import Select from 'react-select'
import { Transaction, User } from "../../graphql/gql/graphql";
import RadioSelect from "../../components/RadioSelect";
import { useAuth } from "../../providers/Auth";
import Input from "../../components/Input";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { redirect, useNavigate } from 'react-router-dom';
import {
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import Icon from "../../components/Icon/Icon";
import NoOffersPlaceholder from "../../components/NoOffersPlaceholder/NoOffersPlaceholder";
import OfferCard from "../../components/OfferCard/OfferCard";
import { offerStateVariantMap } from "../../utils/offerToBadge";
import OfferForm from "../../components/OfferForm";
import Button from "../../components/Button/Button";
import { ButtonVariants } from "../../components/Button/Button.interface";

export interface ISendFunds {
  cardProgramId: string
  phoneNumber: string
  amount: string
}

export interface ISendOffer {
  offerId: string
  phoneNumber: string
}

export interface ICreateOffer {
  name: string
  startDate: string
  endDate: string
  cardProgramId: string
  multiMediaImage?: File
  maxOptins: number
  customMessageRequest: string
  customMessageResponse: string
  loadAmount: number
}


function DashboardInner() {
  const auth = useAuth()
  const navigate = useNavigate()
  const stripe = useStripe();
  const hasRendered = useRef(false)

  useEffect(() => {
    setTimeout(() => {
      hasRendered.current = true
    }, 5000)
  }, [])

  /* State */
  const [transactionOffset, setTransactionOffset] = useState<number>(0)
  const [userEmailFilter, setUserEmailFilter] = useState<string | undefined>(undefined)
  const [userPhoneNumberFilter, setUserPhoneNumberFilter] = useState<string | undefined>(undefined)
  const [userOffset, setUserOffset] = useState<number>(0)
  const [typeFilters, setTypeFilters] = useState<string[]>([])
  const [userTypeFilter, setUserTypeFilter] = useState<string[]>([])
  const [addFundsModalIsOpen, setAddFundsModalIsOpen] = useState<boolean>(false)
  const [createOfferModalIsOpen, setCreateOfferModalIsOpen] = useState<boolean>(false)
  const [addFundsAmount, setAddFundsAmount] = useState<string | undefined>(undefined)
  const [sendFundsModalIsOpen, setSendFundsModalIsOpen] = useState<boolean>(false);
  const [sendOfferModalIsOpen, setSendOfferModalIsOpen] = useState<boolean>(false);
  const [sendFundsData, setSendFundsData] = useState<ISendFunds>({
    amount: "0",
    cardProgramId: "",
    phoneNumber: ""
  })
  const [sendOfferData, setSendOfferData] = useState<ISendOffer>({
    offerId: "",
    phoneNumber: ""
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  /* Queries */
  const [{ data: primaryAccountData, fetching: primaryAccountFetching, error: primaryAccountError }, refetchPrimaryAccount] = useQuery({
    query: primaryAccountQuery,
    requestPolicy: "network-only"
  })
  const [{ data: offersData, fetching: offersFetching, error: offersError }, refetchOffers] = useQuery({
    query: getOffersQuery,
    variables: {
      offset: 0,
      pageNumber: 10,
    },
    requestPolicy: "network-only"
  })
  const [{ data: funnelData, fetching: funnelDataFetching, error: funnelDataError }, refetchFunnelData] = useQuery({
    query: funnelDataQuery,
    requestPolicy: "network-only"

  })
  const [{ data: transactionData, fetching: transactionDataFetching, error: transactionDataError }, refetchTransactions] = useQuery({
    query: getTransactionDataQuery,
    requestPolicy: "network-only"
  })
  const [{ data: cardPrograms, fetching: cardProgramsFetching, error: cardProgramError}, reFetchCardPrograms] = useQuery({
    query: listCardProgramsQuery,
    variables: {
      offset: 0,
      pageNumber: 10,
    },
    requestPolicy: "network-only"
  })
  const [{ data: users, fetching: usersFetching, error: usersError}] = useQuery({
    query: listUsersQuery,
    variables: {
      offset: userOffset,
      pageNumber: 10,
      email: userEmailFilter,
      phoneNumber: userPhoneNumberFilter,
      userType: userTypeFilter
    },
    requestPolicy: "network-only"
  })
  const [{ data: transactions, fetching: transactionsFetching, error: transactionsError}, reExecuteTransactionsQuery] = useQuery({
    query: listTransactionsQuery,
    variables: {
      offset: transactionOffset,
      pageNumber: 10,
      type: typeFilters
    },
    requestPolicy: "network-only"
  })

  useEffect(() => {
    setInterval(() => {
      refetchPrimaryAccount()
      refetchTransactions()
      refetchFunnelData()
      refetchOffers()
      reFetchCardPrograms()
      reExecuteTransactionsQuery()
    }, 10000)
  }, [])

  /* Mutations */
  const [sendFundsResult, sendFunds] = useMutation(sendFundsMutation)
  const [, createPaymentIntent] = useMutation(createPaymentIntentMutation)
  const [, sendOffer] = useMutation(sendOfferMutation)

  /* Application Based Error Handling */
 useEffect(() => {
  let errorMessage = ""
  if (primaryAccountData?.primaryAccount.__typename === 'BaseError') errorMessage += `${primaryAccountData.primaryAccount.message} `
  if (funnelData?.funnelData.__typename === 'BaseError') errorMessage += `${funnelData.funnelData.message} `
  if (cardPrograms?.cardPrograms.__typename === 'BaseError') errorMessage += `${cardPrograms.cardPrograms.message} `
  if (users?.users.__typename === 'BaseError') errorMessage += `${users.users.message} `
  if (transactions?.transactions.__typename === 'BaseError') errorMessage += `${transactions.transactions.message} `
  if (transactionData?.getTransactionData.__typename === 'BaseError') errorMessage += `${transactionData.getTransactionData.message} `
  if (offersData?.offers.__typename === 'BaseError') errorMessage += `${offersData.offers.message} `
  if (errorMessage) {
    toast.error(errorMessage, {
      position: "top-center"
    })
    console.error(errorMessage)
  }
 }, [
  primaryAccountData?.primaryAccount,
  funnelData?.funnelData,
  cardPrograms?.cardPrograms,
  users?.users,
  transactions?.transactions,
  transactionData?.getTransactionData,
  offersData?.offers
])

 /* GraphQL Error Handling */
 useEffect(() => {
  let errorMessage = ""
  if (primaryAccountError) errorMessage += `${primaryAccountError.message} `
  if (funnelDataError) errorMessage += `${funnelDataError.message} `
  if (cardProgramError) errorMessage += `${cardProgramError.message} `
  if (usersError) errorMessage += `${usersError.message} `
  if (transactionsError) errorMessage += `${transactionsError.message} `
  if (offersError) errorMessage += `${offersError.message} `
  if (errorMessage) toast.error(errorMessage, {position: "top-center"})
 }, [
  primaryAccountError,
  funnelDataError,
  cardProgramError,
  usersError,
  transactionsError,
  offersError
 ])



  /* Transaction Data Keys and filtering */
  const TransactionKeys: (keyof Transaction)[] = useMemo(() => [
    "id",
    "timeCreated",
    "timeUpdated",
    "amount",
    "type",
  ], []);
  const filteredTransactions = useMemo(() => {
    if (!transactions?.transactions) return [];
    if (transactions?.transactions.__typename === "BaseError") return [];
    return transactions?.transactions.data.transactions.map((obj: any) => {
      const filteredObj: any = {};
      TransactionKeys.forEach((key: keyof Transaction) => {
        if (key in obj) {
          if (key === "amount") {
            filteredObj[key] = toUsd(obj[key])
          } else {
            filteredObj[key] = obj[key];
          }
        }
      });
      return filteredObj;
    });
  }, [transactions?.transactions, TransactionKeys]);
  const transactionsCount = useMemo(() => {
    if (!transactions?.transactions) return 0;
    if (transactions.transactions.__typename === "BaseError") {
      return 0
    }
    return transactions.transactions.data.count;
  }, [transactions?.transactions])

  /* User Data Keys and filtering */
  const userKeys: (keyof User)[] = useMemo(() => [
    "email",
    "phoneNumber",
    "firstName",
    "lastName",
    "timeCreated",
    "role",
    "id"
  ], [])
  const filteredUsers = useMemo(() => {
    if (!users?.users) return [];
    if (users?.users.__typename === "BaseError") return [];
    return users?.users.data.users.map((obj: any) => {
      const filteredObj: any = {};
      userKeys.forEach((key: keyof User) => {
        if (key in obj) {
          filteredObj[key] = obj[key];
        }
      });
      return filteredObj;
    });
  }, [users?.users, userKeys]);
  const usersCount = useMemo(() => {
    if (!users?.users) return 0;
    if (users.users.__typename === "BaseError") {
      return 0
    }
    return users.users.data.count;
  }, [users?.users])

     /* Card Programs */
     const options = useMemo(() => {
      if (cardPrograms?.cardPrograms.__typename === "BaseError") return []
      return cardPrograms?.cardPrograms.data.cardPrograms.map((obj) => {
        return {
          label: obj.name,
          value: obj.id
        }
      })
    }, [cardPrograms?.cardPrograms])

    /* Offers */
    const offers = useMemo(() => {
      if (offersData?.offers.__typename === "BaseError") return []
      return offersData?.offers.data.offers.map((obj) => {
        return {
          label: obj.name,
          value: obj.id
        }
      })
    }, [offersData?.offers])
  

  /* Add Funds */
  const onAddFundsSubmit = async () => {
    setIsLoading(true)
    if(!primaryAccountData?.primaryAccount) {
      console.error("No primary account data")
      return
    }
    createPaymentIntent({
      amount: parseFloat(addFundsAmount ?? "0"),
    }).then((result) => {
      if (result.data?.createPaymentIntent.__typename === 'MutationCreatePaymentIntentSuccess') {
        navigate(`/checkout/${result.data.createPaymentIntent.data.id}`)
      } else {
        toast.error(result.data?.createPaymentIntent.message, {position: "top-center"})
        console.error(result.data?.createPaymentIntent.message)
      }
    })
    .catch((error) => {
      toast.error(error.message, {position: "top-center"})
      console.error(error)
    })
    .finally(() => {
      setAddFundsModalIsOpen(false)
      setAddFundsAmount(undefined)
      setIsLoading(false)
    })
  }

  /* Send Offer */
  const onSendOfferSubmit = async () => {
    setIsLoading(true)
    sendOffer({
      ...sendOfferData,
      phoneNumber: `+${sendOfferData.phoneNumber}`,
    }).then((result) => {
      if (result.data?.sendOffer.__typename === 'BaseError') {
        toast.error(result.data.sendOffer.message, {position: "top-center"})
        console.error(result.data.sendOffer.message)
        return
      }
      toast.success("Send Offer Successful", {position: "top-center"})
    }).catch((error) => {
      toast.error(error.message, {position: "top-center"})
      console.error(error)
    }).finally(() => {
      setSendOfferModalIsOpen(false)
      // setSendOfferData({
      //   offerId: "",
      //   phoneNumber: ""
      // })
      setIsLoading(false)
    
    })
  }

  /* Send Funds */
  const onSendFundsSubmit = async () => {
    setIsLoading(true)
    sendFunds({
      ...sendFundsData,
      phoneNumber: `+${sendFundsData.phoneNumber}`,
      amount: parseFloat(sendFundsData.amount)
    }).then((result) => {
      if (result.data?.sendFunds.__typename === 'BaseError') {
        toast.error(result.data.sendFunds.message, {position: "top-center"})
        console.error(result.data.sendFunds.message)
        return
      }
      toast.success("Send Funds Successful", {position: "top-center"})
    }).catch((error) => {
      toast.error(error.message, {position: "top-center"})
      console.error(error)
    }).finally(() => {
      setSendFundsModalIsOpen(false)
      // setSendFundsData({
      //   amount: "0.00",
      //   cardProgramId: "",
      //   phoneNumber: ""
      // })
      setIsLoading(false)
    })
  }

  


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        toast.error("Something went wrong.");
        console.error("Something went wrong.");
        return;
      }
      switch (paymentIntent.status) {
        case "succeeded":
          toast.success("Payment succeeded!");
          break;
        case "processing":
          toast.success("Your payment is processing.");
          break;
        case "requires_payment_method":
          toast.error("Your payment was not successful, please try again.");
          break;
        default:
          toast.error("Something went wrong.");
          console.error("Something went wrong.");
          break;
      }
    });
    navigate("/")
  }, [stripe]);


  const totalCards = useMemo(() => {
    let total = 0
    if (cardPrograms?.cardPrograms.__typename === "QueryCardProgramsSuccess") {
      for (const cardProgram of cardPrograms.cardPrograms.data.cardPrograms) {
        total += cardProgram.totalCards
      }
    }
    return total
  }, [cardPrograms?.cardPrograms])

  return (
    <div className={styles.root}>
      <div className="flex justify-between col-span-2 items-center">
        <span className="my-6 ml-5 typo-h2 font-bold tracking-tight text-gray-900 dark:text-white">Dashboard</span>
        <div className="flex gap-5">
        <Button 
          label="Send Funds"
          variant={ButtonVariants.PRIMARY}
          onClick={() => setSendFundsModalIsOpen(true)}
        />
        <Button 
          label="Send Offer"
          variant={ButtonVariants.PRIMARY}
          onClick={() => setSendOfferModalIsOpen(true)}

        />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 col-span-2">
        <Card className="p-2">
        <h5 className={styles.accountBalanceHeader}>Card Transactions</h5>
        <p className="typo-h3 ml-5 my-5 text-gray-700 dark:text-gray-400">{transactionData?.getTransactionData.__typename === "QueryGetTransactionDataSuccess" && transactionData.getTransactionData.data.cardTransactions}</p>
        </Card>
        <Card className="p-2">
          <h5 className={styles.accountBalanceHeader}>Cards Created</h5>
          <p className="typo-h3 ml-5 my-5 text-gray-700 dark:text-gray-400">{totalCards}</p>
        </Card>
        <Card className="p-2">
          <h5 className={styles.accountBalanceHeader}>Transactional Volume</h5>
          <p className="typo-h3 ml-5 my-5 text-gray-700 dark:text-gray-400">{transactionData?.getTransactionData.__typename === "QueryGetTransactionDataSuccess" && toUsd(transactionData.getTransactionData.data.transactionalVolume)}</p>
        </Card>
      </div>
      <div className="flex flex-col h-full">
        <Card>
          <div className="flex justify-between bg-gray-100 dark:bg-gray-700 rounded-t-lg">
            <h5 className={styles.accountBalanceHeader}>Account Balance</h5>
            <button
              className={styles.addFundsButton}
              onClick={() => setAddFundsModalIsOpen(true)}
            >Add Funds</button>
          </div>
          {(primaryAccountData?.primaryAccount && primaryAccountData?.primaryAccount.__typename === "QueryPrimaryAccountSuccess") && <p className="typo-h2 ml-5 my-5 text-gray-700 dark:text-gray-400">{toUsd(primaryAccountData.primaryAccount.data.balance)}</p>}
          <div className="border-2 border-dashed mt-10 mx-5"></div>
          <div className={`${styles.bottomText}`}>
            <div className={`${styles.moneyTags}`}><Icon.MoneyTag/></div>
            <div className={`${styles.forSmsFee}`}> 
          <span className={`${styles.forSmsFeeNumber}`}>{transactionData?.getTransactionData.__typename === "QueryGetTransactionDataSuccess" && toUsd(transactionData.getTransactionData.data.fees)}</span> of budget used for advertising fees
        </div>
      </div>
        </Card>
        {/* <CardPrograms cardPrograms={cardPrograms?.cardPrograms}/> */}
      </div>
      
      <Card className={styles.engagements}>
      <p className={styles.accountBalanceHeader}>Engagements</p>
      {(funnelData?.funnelData && funnelData?.funnelData.__typename === "QueryFunnelDataSuccess") &&
      <div className="grid grid-cols-2 gap-5">
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">Sent</p>
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">{funnelData.funnelData.data.sent}</p>
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">Delivered</p>
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">{funnelData.funnelData.data.delivered}</p>
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">Viewed</p>
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">{funnelData.funnelData.data.viewed}</p>
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">Purchased</p>
        <p className="typo-h3 ml-5 text-gray-700 dark:text-gray-400">{funnelData.funnelData.data.purchased}</p>
      </div>
      }
      </Card>
      <div className="flex justify-between col-span-2 items-center">
        <span className="my-5 ml-5 typo-h2 font-bold tracking-tight text-gray-900 dark:text-white">Offers</span>
        <Button 
          label="Create Offer"
          variant={ButtonVariants.PRIMARY}
          onClick={() => setCreateOfferModalIsOpen(true)}
        />
      </div>
      {(!hasRendered.current && offersFetching) ? <div className="my-40 flex mx-auto w-fit col-span-2"><Spinner /></div> : <>
        {offersData?.offers.__typename === "QueryOffersSuccess" && offersData.offers.data.offers.length > 0 ? 
        <>
          {offersData?.offers.data.offers.map((offer) => (
            <div className="col-span-2" key={offer.id}>
              <OfferCard 
                title={offer.name}
                budgetRemains={(offer.optIns/offer.maxOptIns) * 100}
                cardsCreated={`${offer.optIns}`}
                cardsRedeemed={`${offer.redemptions}`}
                cardsRedeemedPerCent={`${offer.optIns !== 0 ? offer.redemptions/offer.optIns * 100 : 0}`}
                topRightLinkCallback={() => navigate(`/offers/${offer.id}`)}
                variant={offerStateVariantMap(offer.status)}
                addFundsCallBack={() => console.log("TODO")}
                key={offer.id}
              />
            </div>
          ))}
        </> :
        <div className="col-span-2 flex mx-auto mb-4">
          <NoOffersPlaceholder
            title="No Offers"
            content="You have not created any offers yet. Click the button below to create your first offer."
            buttonLabel="Create Offer"
            onClick={() => setCreateOfferModalIsOpen(true)}
            
          />
      </div>}
      </>}

      {/* Transactions Table */}
      <Card className="col-span-2 p-6">
        <p className="typo-h2 dark:text-white mb-2">Transactions</p>
        <p className="text-grey dark:text-gray-300 typo-p-small mb-5">View Transactions, the flow of funds going in and out of your account.</p>
        <div className="flex gap-4 pb-5">
        <RadioSelect 
          options={[
            {
              label: "Internal",
              value: "INTERNAL"
            },
            {
              label: "Card Transaction",
              value: "CARD_TRANSACTION"
            },
            {
              label: "Sms Fee",
              value: "SMS_FEE"
            },
            {
              label: "Card Create Fee",  
              value: "CARD_CREATE_FEE"
            },
            {
              label: "Card Transaction Fee",
              value: "CARD_TRANSACTION_FEE"
            },
            {
              label: "Card TransactionVoid",
              value: "CARD_TRANSACTION_VOID"
            },
            {
              label: "Card Transaction Settled",
              value: "CARD_TRANSACTION_SETTLED"
            },
            {
              label: "Stripe Fee",
              value: "STRIPE_FEE"
            },
            {
              label: "STRIPE",
              value: "STRIPE"
            },
            {
              label: "Send Funds Fee",
              value: "SEND_FUNDS_FEE"
            },
            {
              label: "Sends Funds",
              value: "SEND_FUNDS"
            },
            {
              label: "Receive funds",
              value: "RECEIVE_FUNDS"
            },
            {
              label: "Offer opt in",
              value: "OFFER_OPT_IN"
            },
            {
              label: "Send offer",
              value: "SEND_OFFER"
            },
            {
              label: "Offer Opt-in Fee",
              value: "OFFER_OPT_IN_FEE"
            },

          ]}
          name="Filter by Type"
          handleClick={(key, value) => handleFilter(key, value, typeFilters, setTypeFilters)}
        />
        </div>
      <Table
          loading={!hasRendered.current && transactionsFetching}
          columns={TransactionKeys}
          data={filteredTransactions}
          totalItems={transactionsCount}
          itemsPerPage={10}
          onNextPage={() => {
            // logic to move to the next page
            setTransactionOffset(transactionOffset + 10)
          }} 
          onPrevPage={() => {
            // logic to move to the previous page
            setTransactionOffset(transactionOffset - 10)
          }}
        />
      </Card>

      {/* Users Table */}
      {auth.userData?.role === "admin" && <Card className="col-span-2 p-6">
        <p className="typo-h2 dark:text-white mb-2">Users</p>
        <p className="text-grey dark:text-gray-300 typo-p-small mb-5">
          View all the users you have engaged in your card programs. 
          {auth.userData?.role === "admin" && " You are an admin User. You are viewing all users, not just your card program users."}
        </p>
        <div className="mb-6">
        {auth.userData?.role === "admin" && <RadioSelect 
          options={[
            {
              label: "Dashboard User",
              value: "user"
            },
            {
              label: "End User",
              value: "endUser"
            },
            {
              label: "Admin User",
              value: "admin"
            },
          ]}
          name="Filter by Type"
          handleClick={(key, value) => handleFilter(key, value, userTypeFilter, setUserTypeFilter)}
        />}
        <div className="md:flex gap-4 mt-5">
          <Input
            label="Phone Number"
            placeholder="Filter by phone number"
            onChangeValue={(phoneNumber) => setUserPhoneNumberFilter(phoneNumber)}
            name="phoneNumber"
          />
          <Input
            label="Email"
            placeholder="Filter by email"
            onChangeValue={(email) => setUserEmailFilter(email)}
            name="email"
          />
        </div>
        </div>
      <Table
      view={{
        label: "View",
        path: "/users"
      }}
          loading={usersFetching}
          columns={userKeys}
          data={filteredUsers}
          totalItems={usersCount}
          itemsPerPage={10}
          onNextPage={() => {
            // logic to move to the next page
            setUserOffset(userOffset + 10)
          }}
          onPrevPage={() => {
            // logic to move to the previous page
            setUserOffset(userOffset - 10)
          }}
        />
      </Card>}
      <Modal isOpen={addFundsModalIsOpen} title="Add funds" onClose={() => setAddFundsModalIsOpen(false)}>
        <div className="mx-5 my-2">
            <span className="text-grey dark:text-gray-300 typo-p-small">
                Add funds to your account. After clicking submit, you will be redirected to a checkout page.
            </span>
            <CurrencyInput
              id="input-example"
              name="addFunds"
              placeholder="Please enter a dollar amount"
              value={addFundsAmount}
              defaultValue={0}
              decimalsLimit={2}
              prefix="$"
              className="h-14 bg-gray-50 typo-p-large border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onValueChange={(value, _name) => {
                setAddFundsAmount(value)
              }}
            />
            <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
                <button type="button" onClick={() => onAddFundsSubmit()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
              }
            </div>
        </div>
      </Modal>
      <Modal isOpen={sendFundsModalIsOpen} title="Send funds" onClose={() => setSendFundsModalIsOpen(false)}>
        <div className="mx-5 my-2">
            <span className="text-grey dark:text-gray-300 typo-p-small">
                Send Funds to someone. Enter an amount and what card program you want.
            </span>
            <div className="my-6">
              <p className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Card Program</p>
              <Select 
                options={options}
                className="typo-p-small dark:bg-gray-700"
                onChange={(value) => setSendFundsData({
                  ...sendFundsData,
                  cardProgramId: value?.value || "",
                })}
                isLoading={cardProgramsFetching}
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Phone number</label>
              <PhoneInput
                country={'us'}
                containerClass="!bg-black"
                inputClass="!bg-gray-50 !border !border-gray-300 !text-gray-900 !typo-p-small !rounded-lg !focus:ring-blue-500 !focus:border-blue-500 !block !w-full !dark:bg-gray-700 !dark:border-gray-600 !dark:placeholder-gray-400 !dark:text-white !dark:focus:ring-blue-500 !dark:focus:border-blue-500"
                value={sendFundsData.phoneNumber}
                onChange={(value) => setSendFundsData({
                  ...sendFundsData,
                  phoneNumber: value
                })}
              />
                
            </div>
            <div className="mb-6">
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Amount</label>
              <CurrencyInput
                id="input-example"
                name="amount"
                placeholder="Please enter a dollar amount"
                value={addFundsAmount}
                defaultValue={0}
                decimalsLimit={2}
                prefix="$"
                className="h-14 bg-gray-50 typo-p-small border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onValueChange={(value, _name) => setSendFundsData({
                  ...sendFundsData,
                  amount: value || "0"
                })}
              />
            </div>
            <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
                <button type="button" onClick={() => onSendFundsSubmit()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
              }
            </div>
        </div>
      </Modal>
      <Modal isOpen={sendOfferModalIsOpen} title="Send Offer" onClose={() => setSendOfferModalIsOpen(false)}>
        <div className="mx-5 my-2">
            <span className="text-grey dark:text-gray-300 typo-p-small">
                Send an offer to someone. Select the offer and enter the phone number of the recipient.
            </span>
            <div className="my-6">
              <p className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Offer</p>
              <Select 
                options={offers}
                className="typo-p-small dark:bg-gray-700"
                onChange={(value) => setSendOfferData({
                  ...sendOfferData,
                  offerId: value?.value || "",
                })}
                isLoading={cardProgramsFetching}
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 typo-p-small font-medium text-gray-900 dark:text-white">Phone number</label>
              <PhoneInput
                country={'us'}
                containerClass="!bg-black"
                inputClass="!bg-gray-50 !border !border-gray-300 !text-gray-900 !typo-p-small !rounded-lg !focus:ring-blue-500 !focus:border-blue-500 !block !w-full !dark:bg-gray-700 !dark:border-gray-600 !dark:placeholder-gray-400 !dark:text-white !dark:focus:ring-blue-500 !dark:focus:border-blue-500"
                value={sendFundsData.phoneNumber}
                onChange={(value) => setSendOfferData({
                  ...sendOfferData,
                  phoneNumber: value
                })}
              />
                
            </div>
            <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
                <button type="button" onClick={() => onSendOfferSubmit()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
              }
            </div>
        </div>
      </Modal>
      <OfferForm 
        cardPrograms={options}
        cardProgramsLoading={cardProgramsFetching}
        createOfferModalIsOpen={createOfferModalIsOpen}
        setCreateOfferModalIsOpen={setCreateOfferModalIsOpen}
      />
      
    </div>
  )
}

const Dashboard: React.FC = () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(Promise.resolve(null))

  const [{ data: publishableKey, fetching: _publishableKeyFetching, error: publishableKeyError }] = useQuery({
    query: getPublishableKeyQuery,
  })
  useEffect(() => {
    if(publishableKey?.getPublishableKey.__typename === "QueryGetPublishableKeySuccess") {
      setStripePromise(loadStripe(publishableKey.getPublishableKey.data.publishableKey))
    }
  }, [publishableKey])
  return (
      <Elements stripe={stripePromise}>
          <DashboardInner />
      </Elements>
  )
}

export default Dashboard