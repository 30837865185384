import { FC, useState, useEffect, useRef } from 'react'
import CurrencyInputComponent from 'react-currency-input-field'
import IconHelp from '../Icon/Help'
import Button from '../Link/Link'
import CurrencyInputProps from './CurrencyInput.interface'
import styles from './CurrencyInput.module.scss'

const CurrencyInput: FC<CurrencyInputProps> = ({
  id,
  label,
  errorText,
  underText,
  helpText,
  disabled,
  onChangeValue,
  onClickLink,
  defaultValue = 0,
  placeholder,
  name,
  register,
  validation,
  customClassName,
  value
}) => {
  const [valueInput, setValueInput] = useState<string | number | undefined>(defaultValue)

  useEffect(() => {
      setValueInput(defaultValue)
    },
    [defaultValue]
  )

  const onChangeInput = (value?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onChangeValue && onChangeValue(value || '')
    setValueInput(value)
  }

  const onClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onClickLink && onClickLink()
  }

  const inputReference = useRef<HTMLInputElement | null>(null)
  const {ref, ...rest} = register(name, {...validation})

  return <div className={`${styles.root} ${customClassName}`}>
    <div className={`${styles['group-label']}`}>
      <label className={`${errorText && styles['error-label']}`} htmlFor={id}>{label}</label>
      {helpText && <>
        <Button label={helpText} className={`${styles['link-currency']}`} onClick={onClick} />
        <Button label={<IconHelp color='#027FAC' width={16} height={16} />} className={`${styles['icon-link-currency']}`} onClick={onClick} />
      </>}
    </div>
        {value ? 
            <CurrencyInputComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            ref={(e) => {
              ref(e)
              inputReference.current = e
            }}
            id={id}
            name={name}
            className={`${errorText && styles['error-input']}`}
            prefix="$"
            allowDecimals
            decimalScale={2}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            onValueChange={onChangeInput}
          /> : 
          <CurrencyInputComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          ref={(e) => {
            ref(e)
            inputReference.current = e
          }}
          id={id}
          name={name}
          className={`${errorText && styles['error-input']}`}
          prefix="$"
          allowDecimals
          decimalScale={2}
          disabled={disabled}
          value={valueInput || 0}
          placeholder={placeholder}
          onValueChange={onChangeInput}
        />
        }

    {errorText && <span className={`${styles['error-message']}`}>{errorText}</span>}
    {underText && <span className={`${styles['under-text']}`}>{underText}</span>}
  </div>
}

export default CurrencyInput
