import { ChangeEvent, FC, useCallback, useEffect, useId, useState, useRef } from 'react'
import styles from './DatePicker.module.scss'
import DatePickerProps from './DatePicker.interface'
import IconComponent from "../Icon/Icon"

const DatePicker: FC<DatePickerProps> = ({
  defaultValue,
  onChangeValue,
  placeholder,
  label,
  name,
  register,
  validation,
  customClassName
}) => {
  const formattedDate = 'en-CA' // dd-mm-yyyy
  const formatDate = (value: string) => new Date(value).toLocaleDateString(formattedDate)
  const [startDate, setStartDate] = useState(formatDate(defaultValue))
  useEffect(() => {
    setStartDate(formatDate(defaultValue))
  }, [defaultValue])

  const id = useId()
  const handleChange = useCallback(
    (newValue: ChangeEvent<HTMLInputElement>) => {
      onChangeValue?.(newValue.target.value)
      setStartDate(newValue.target.value)
    },
    [onChangeValue],
  )

  const inputReference = useRef<HTMLInputElement | null>(null)
  const {ref, ...rest} = register(name, {...validation})

  return (
    <div className={`${customClassName}`}>
      <label htmlFor={`datetime-${id}`} className={styles.label}>{label}</label>
      <div className={styles['wrapper-input']}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          ref={(e) => {
            ref(e)
            inputReference.current = e
          }}
          type='date'
          id={`datetime-${id}`}
          name={name}
          defaultValue={startDate}
          placeholder={placeholder}
          onChange={handleChange}
          className={styles.input}
        />
        <div className={styles.icon}>
          <IconComponent.Calendar />
        </div>
      </div>
    </div>
  )
}

export default DatePicker
