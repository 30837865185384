/* eslint-disable */
import { FC } from 'react'
import RedeemedCardProps from './RedeemedCard.interface'
import styles from './RedeemedCard.module.scss'
import { toUsd } from '../../utils/data'
import Icon from '../Icon/Icon'
import RedemptionModal from '../../components/RedemptionModal/RedemptionModal'
import { useTheme } from '../../providers/Theme'

const RedeemedCard: FC<RedeemedCardProps> = ({ redemptions, optIns, perCard }) => {
  const { mode } = useTheme()
  return (
    <div className={`${styles.root}`}>
    <div className={`${styles.titleWrapper}`}>
      <div className={`${styles.title}`}>
        Redemptions
      </div>
      <RedemptionModal />
    </div>
    <div className={`${styles.middleContainer}`}>
      <div className={`${styles.percent}`}>{redemptions}</div>
      <div className={`${styles.perCard}`}>{toUsd(perCard)} per card</div>
    </div>
    <div className={`${styles.bottomContainer}`}>
      <Icon.Star className={`${styles.star}`} color={mode === "dark" ? "white" : "blue"}/>
      <div className={`${styles.cardsCreated}`}>
        <span className={`${styles.cardsCreatedNumber}`}>
          {optIns}
        </span>
         &nbsp; {optIns === 1 ? "person" : "people"} have opted into this offer.
      </div>
    </div>
  </div>
  )
}

export default RedeemedCard
