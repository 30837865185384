import { useEffect, useState } from 'react'

interface Screens {
  [key: string]: string | number
}

export default function useIsMobile(breakPoint?: string, defaultState: boolean = true){
  const [isMobile, setIsMobile] = useState(defaultState)

    const screens: Screens = {
      'sm': '375',
      'md': '768',
      'lg': '1024',
      'xl': '1440',
    }

  const bpWidth = screens[breakPoint || 'md']
  const onResize = () => {
    setIsMobile(window.innerWidth < bpWidth)
  }
  const events = ['resize', 'orientationchange']

  useEffect(() => {
    // trigger callback once after initial rendering
    onResize()
    // add event listeners for all events from the array above
    events.forEach((e) => {
      window.addEventListener(e, () => {
        onResize()
      })
    })
    // remove event listeners for all events from the array above
    return () => {
      events.forEach((e) => {
        window.removeEventListener(e, () => {})
      })
    }
  }, [])
  return isMobile
}