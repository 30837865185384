import { FC, useMemo, useState } from 'react'
import CardProgramsProps from './CardPrograms.interface'
import styles from './CardPrograms.module.scss'
import Card from "../../components/card";
import Modal from '../Modal';
import Spinner from '../../components/Spinner';
import Input from '../Input';
import DropZone from '../DropZone';
import { createCardProgramMutation } from '../../graphql/mutations';
import { useMutation } from 'urql';
import { toast } from "react-toastify";

interface ICardProgram {
  name: string
  phoneNumber: string
  cardProgramNetworkId: string
  cardArtwork?: File
  userId: string
}

const CardPrograms: FC<CardProgramsProps> = ({cardPrograms, displayCreate, userId}) => {

  /* State */
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [createProgramModalIsOpen, setCreateProgramModalIsOpen] = useState<boolean>(false)
  const [cardProgram, setCardProgram] = useState<ICardProgram>({
    cardProgramNetworkId: "2ca0321d-e875-4165-ad01-7f4e22a2f2c9",
    name: '',
    phoneNumber: '',
    userId: userId || ""
  })

  const cardProgramsData = useMemo(() => {
    if (cardPrograms?.__typename === "BaseError") {
      return {
        cardPrograms: [],
        count: 0
      }
    }
    return cardPrograms?.data
  }, [cardPrograms])

  /* Mutations */
  const [createCardProgramsResult, createCardPrograms] = useMutation(createCardProgramMutation)

  const onCreateProgram = () => {
    createCardPrograms({
      cardProgramNetworkId: cardProgram.cardProgramNetworkId,
      name: cardProgram.name,
      phoneNumber: cardProgram.phoneNumber,
      userId: cardProgram.userId
    }).then((result) => {
      if(result.data?.createCardProgram.__typename === "BaseError") {
        console.error(result.data.createCardProgram.message)
        toast.error(result.data.createCardProgram.message, {
          position: "top-center"
        })
        return
      }
      if (!result.data?.createCardProgram.data.cardArtworkPresignedUrl) {
        console.error("No card artwork presigned url")
        return
      }
      if (!cardProgram.cardArtwork) {
        console.error("No card artwork")
        return
      }
      fetch(result.data.createCardProgram.data.cardArtworkPresignedUrl, {
        body: cardProgram.cardArtwork,
        method: 'PUT',
        headers: {
          'Content-Type': cardProgram.cardArtwork.type,
        }
      }).then((result) => {
        toast.success("Create Card Program Successful", {
          position: "top-center"
        })
      }).catch((error) => {
        console.error(error)
        toast.error(error.message, {
          position: "top-center"
        })
      })
    }).catch((error) => {
      console.error(error)
      toast.error(error.message, {
        position: "top-center"
      })
    }).finally(() => {
      setCreateProgramModalIsOpen(false)
      setCardProgram({
        cardProgramNetworkId: "2ca0321d-e875-4165-ad01-7f4e22a2f2c9",
        name: '',
        phoneNumber: '',
        userId: userId || ""
      })
      setIsLoading(false)
    })
  }
return (
  <>
    <Card className={styles['card-programs']}>
    {cardPrograms && 
      <>
      <div className="flex justify-between">
        <div>
          <h5 className="mb-2 mr-5 typo-h3 font-bold tracking-tight text-gray-900 dark:text-white">Card Programs</h5>
          {displayCreate && <p onClick={() => setCreateProgramModalIsOpen(true)}className='text-gray-700 dark:text-gray-400 typo-p-small hover:text-gray-400 hover:cursor-pointer dark:hover:text-gray-200'>Create</p>}
        </div>
        <p className="typo-h3 font-bold tracking-tight text-gray-900 dark:text-white mb-6">{cardProgramsData?.count} total</p>
      </div>
      {cardProgramsData?.cardPrograms.map((cardProgram) => (
        <div key={cardProgram.id} className="py-4">
          <div className="flex justify-between items-center">
            <div className="flex">
              <img src={cardProgram.cardArtworkUrl} className="h-20 me-4" alt="Jib Logo" />
              <div>
                <p className="typo-p-small text-gray-900 dark:text-gray-400">{cardProgram.name}</p>
                <p className="typo-p-small text-gray-900 dark:text-gray-400">{cardProgram.phoneNumber}</p>
              </div>
            </div>
            <p className="typo-p-medium text-gray-900 dark:text-gray-400">{cardProgram.totalCards} Cards</p>
          </div>
          <div className="h-1 bg-black dark:bg-gray-500 mt-5"></div>
        </div>
      ))}
      </>}
    </Card>
    <Modal isOpen={createProgramModalIsOpen} title="Create Card Program" onClose={() => setCreateProgramModalIsOpen(false)}>
        <div className="mx-5 my-2">
            <span className="text-grey dark:text-gray-300 typo-p-small">
                Create a card program. This will create a card program for the user you are currently viewing.
            </span>
            <Input 
              label='Name'
              placeholder='Enter name'
              onChange={(e) => setCardProgram({...cardProgram, name: e})}
            />
            <Input 
              label='Phone Number'
              placeholder='enter the phone number'
              onChange={(e) => setCardProgram({...cardProgram, phoneNumber: e})}
            />
            <Input 
              label='Card Program Network Id' 
              placeholder='enter the network id for the program'
              onChange={(e) => setCardProgram({...cardProgram, cardProgramNetworkId: e})}
              value={cardProgram.cardProgramNetworkId}
            />
            <DropZone 
              label='Card Artwork'
              onFileChange={(file) => setCardProgram({...cardProgram, cardArtwork: file})}
            />
            <div className="flex justify-end mt-2">
              {isLoading ?
                <div className="my-3"><Spinner /></div> 
              :
                <button type="button" onClick={() => onCreateProgram()} className="py-2 mt-3 px-5 mr-2 mb-2 typo-p-small text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Submit</button>
              }
            </div>
        </div>
      </Modal>
  </>
)
}

export default CardPrograms
